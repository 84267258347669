import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const SalesReport = Loadable(lazy(() => import('./SalesReport')))
const DailySalesReport = Loadable(lazy(() => import('./DailySalesReport')))
const SpotCheckReport = Loadable(lazy(() => import('./SpotCheckReport')))
const StockOnHandReport = Loadable(lazy(() => import('./StockOnHandReport')))
const CibReport = Loadable(lazy(() => import('./CibReport')))
const ConnectionReport = Loadable(lazy(() => import('./ConnectionReport')))
//const RicaReport = Loadable(lazy(() => import('./RicaReport')))

const reportRoutes = [
    {
        path: '/report/sales',
        element: <SalesReport />,
        auth: authRoles.admin,
    },
    {
        path: '/report/dailysales',
        element: <DailySalesReport />,
        auth: authRoles.admin,
    },
    {
        path: '/report/spotcheck',
        element: <SpotCheckReport />,
        auth: authRoles.admin,
    },
    // {
    //     path: '/report/stockonhand',
    //     element: <StockOnHandReport />,
    //     auth: authRoles.admin,
    // }
    {
        path: '/report/cib',
        element: <CibReport />,
        auth: authRoles.admin,
    },
    {
        path: '/report/connection',
        element: <ConnectionReport />,
        auth: authRoles.admin,
    },
    // {
    //     path: '/report/rica',
    //     element: <RicaReport />,
    //     auth: authRoles.admin,
    // },
]

export default reportRoutes
