import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const AppMap = Loadable(lazy(() => import('./AppMap')))
const AppMapMarQCell = Loadable(lazy(() => import('./AppMapMarQCell')))
const AppMapMarSuper = Loadable(lazy(() => import('./AppMapMarSuper')))
const AppMapMarP2 = Loadable(lazy(() => import('./AppMapMarP2')))
const AppMapShingie = Loadable(lazy(() => import('./AppMapShingie')))

const mapRoutes = [
    {
        path: '/map',
        element: <AppMap />,
        auth: authRoles.admin,
    },
    {
        path: '/map1',
        element: <AppMapMarQCell />,
        auth: authRoles.admin,
    },
    {
        path: '/map2',
        element: <AppMapMarSuper />,
        auth: authRoles.admin,
    },
    {
        path: '/map3',
        element: <AppMapMarP2 />,
        auth: authRoles.admin,
    },
    {
        path: '/map4',
        element: <AppMapShingie />,
        auth: authRoles.admin,
    },
]

export default mapRoutes
