export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
    },
    {
        label: 'REPORTS',
        type: 'label',
    },
    {
        name: 'Reports',
        icon: 'assignment',
        children: [
            {
                name: 'Sales',
                iconText: 'SI',
                path: '/report/sales',
            },
            {
                name: 'Daily Sales',
                iconText: 'SI',
                path: '/report/dailysales',
            },
            {
                name: 'Spot Checks',
                iconText: 'SI',
                path: '/report/spotcheck',
            },
            // {
            //     name: 'Stock on Hand',
            //     iconText: 'SI',
            //     path: '/report/stockonhand',
            // }
            {
                name: 'CIB',
                iconText: 'SI',
                path: '/report/cib',
            },
            {
                name: 'Connections',
                iconText: 'SI',
                path: '/report/connection',
            },
            // {
            //     name: 'Rica',
            //     iconText: 'SI',
            //     path: '/report/rica',
            // },
        ],
    },
    {
        label: 'MAPS',
        type: 'label',
    },
    {
        name: 'Maps',
        path: '/map',
        icon: 'map',
        // children: [
        //     {
        //         name: 'Full',
        //         iconText: 'SI',
        //         path: '/map',
        //     },
        //     {
        //         name: 'Mar 2022 - QCell',
        //         iconText: 'SI',
        //         path: '/map1',
        //     },
        //     {
        //         name: 'Mar 2022 - Super Cellular',
        //         iconText: 'SI',
        //         path: '/map2',
        //     },
        //     {
        //         name: 'Mar 2022 - P2 Mobile',
        //         iconText: 'SI',
        //         path: '/map3',
        //     },
        //     {
        //         name: 'Shingie Test',
        //         iconText: 'SI',
        //         path: '/map4',
        //     }
        // ],
    },
    // {
    //     label: 'Components',
    //     type: 'label',
    // },
    // {
    //     name: 'Components',
    //     icon: 'favorite',
    //     badge: { value: '30+', color: 'secondary' },
    //     children: [
    //         {
    //             name: 'Auto Complete',
    //             path: '/material/autocomplete',
    //             iconText: 'A',
    //         },
    //         {
    //             name: 'Buttons',
    //             path: '/material/buttons',
    //             iconText: 'B',
    //         },
    //         {
    //             name: 'Checkbox',
    //             path: '/material/checkbox',
    //             iconText: 'C',
    //         },
    //         {
    //             name: 'Dialog',
    //             path: '/material/dialog',
    //             iconText: 'D',
    //         },
    //         {
    //             name: 'Expansion Panel',
    //             path: '/material/expansion-panel',
    //             iconText: 'E',
    //         },
    //         {
    //             name: 'Form',
    //             path: '/material/form',
    //             iconText: 'F',
    //         },
    //         {
    //             name: 'Icons',
    //             path: '/material/icons',
    //             iconText: 'I',
    //         },
    //         {
    //             name: 'Menu',
    //             path: '/material/menu',
    //             iconText: 'M',
    //         },
    //         {
    //             name: 'Progress',
    //             path: '/material/progress',
    //             iconText: 'P',
    //         },
    //         {
    //             name: 'Radio',
    //             path: '/material/radio',
    //             iconText: 'R',
    //         },
    //         {
    //             name: 'Switch',
    //             path: '/material/switch',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Slider',
    //             path: '/material/slider',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Snackbar',
    //             path: '/material/snackbar',
    //             iconText: 'S',
    //         },
    //         {
    //             name: 'Table',
    //             path: '/material/table',
    //             iconText: 'T',
    //         },
    //     ],
    // },
    // {
    //     name: 'Charts',
    //     icon: 'trending_up',

    //     children: [
    //         {
    //             name: 'Echarts',
    //             path: '/charts/echarts',
    //             iconText: 'E',
    //         },
    //     ],
    // },
    // {
    //     name: 'Documentation',
    //     icon: 'launch',
    //     type: 'extLink',
    //     path: 'http://demos.ui-lib.com/matx-react-doc/',
    // },
]
